$(function() {

	/**
	 *  Preload
	 */

	$(window).on('load', function() {
		$('.preload').removeClass('preload');
	});

	/**
	 *	Map
	 */

	$('.m-map').each(function(){
		// create map
		new_map( $(this) );

	});

	/**
	 * 	Mobile Nav
	 */

	$('#m-hamburger').on('click', function() {

		$('.m-mainnav').toggleClass('is-active');
		$('body').toggleClass('nav-is-active');
		$('body').toggleClass('desktop');

	});

	browserWidth = $(window).innerWidth();
	browserHeight = $(window).innerHeight();

	/* ------------------------------------
		Swiss Window
	------------------------------------ */

	/*
	*	Navigation
	*/

	if(!isMobile.any) { 
		$('[data-background]').mouseover(function(){
			$(this).css('background-color', $(this).data('background'));
		}).mouseout(function(){
		    $(this).css('background-color', 'white');
		});
	}

	$('.m-swgrid__image').on('click', function() {
		var linkTitle = $(this).find('.m-swgrid__title')[0]; // get clicked title
		var idToLoad = $(this).data('link'); // get navid
		var menuItem = $('li[data-link='+ idToLoad +']'); // get menuitems from main-nav
		var backgroundToLoad = $(menuItem).data('background'); // get background-color from main-nav
		var menuItemChilds = $(menuItem).find('.sub-menu')[0]; // get data of submenu
		var menuItemCount = $(menuItemChilds).find('li'); // get all li-childs
		var itemsPerColumn = Math.ceil(menuItemCount.length/3); // always round up

		var menuList = '';
		var itemCount = 0;

		// loop li-childs until itemsPerColumn is reached, start again
		$(menuItemCount).each(function(){
			menuList += '<li>'+$(this).html()+'</li>';

			/*itemCount++;

			if(itemCount == itemsPerColumn) {
				menuList += '</ul><ul>';
				itemCount = 0;
			}*/
		});


		$('.m-swgrid__menu-nav').before('<h3>'+$(linkTitle).html()+'</h3>');
		$('.m-swgrid__menu-nav').html('<ul>'+menuList+'</ul>');

		$('.m-swgrid__menu-content').css('background-color', backgroundToLoad);
		$('.m-swgrid__menu').show();

	});


	$('.m-swgrid__close, .m-swgrid__menu').on('click',function() {
		// hide and clear nav
		$('.m-swgrid__menu-content h3').remove();
		$('.m-swgrid__menu').hide();
		$('.m-swgrid__menu-nav').html('');

		return false;
	});

	$('.m-swgrid__menu-nav').on('click','a',function(event) {
		event.stopPropagation();
	});

	/* 
		Kantonsselector
		If selected, only show Companies of selected canton
	*/

	$('.m-cantonselect').on('change', function() {
		selectedOption = $('.m-cantonselect option:selected').val();

		if(selectedOption != '') {
			$(".m-companies__detail").hide();
			$(".m-companies__detail[data-cantons*='"+selectedOption+"']").show();
		} else {
			$(".m-companies__detail").show();
		}


	});

	$('.m-references').each(function(){

        // Prepare layout options.
        var options = {
          itemWidth: 340, // Optional min width of a grid item
          autoResize: true, // This will auto-update the layout when the browser window is resized.
          container: $(this), // Optional, used for some extra CSS styling
          offset: 30, // Optional, the distance between grid items
          outerOffset: 0, // Optional the distance from grid to parent
          flexibleWidth: 383 // Optional, the maximum width of a grid item
        };

		if($(window).width() < 960) {
			options.flexibleWidth = '100%';
			options.itemWidth = 250;
			options.offset = 20;
		}

		// Call the layout function.
		$(this).imagesLoaded(function() {
			$('.m-references').wookmark(options);
		});
		$('.m-references').wookmark(options);

	});



	/**
	 * Responsive
	 */

	 function responsive() {

        // Mobile Nav
        /*if($(window).width() <= 980){

            $('.m-servicenav').appendTo('.m-mainnav__primary');

            $mobile_language = $('.pll-parent-menu-item--copy');
            if ( !$mobile_language.length ) {
            	$('.pll-parent-menu-item').find('.sub-menu').clone()
            		.addClass('pll-parent-menu-item--copy')
            		.removeClass('.sub-menu')
            		.insertAfter('.m-servicenav__menu');
            }*/

        // Desktop
        /*} else {

            $('.m-servicenav').insertBefore('.m-mainnav');

        }*/

        // Mobile
       /* if($(window).width() <= 980){

            $('body').addClass('mobile');

        } else {

            $('body').removeClass('mobile');

        }*/

    }

    function windowResize(){
        $(window).resize(function(){
            responsive();
        });
    }

    responsive();
    windowResize();

});



/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function new_map( $el ) {

	// vars
	var args = {
		zoom		: 15,
		center		: new google.maps.LatLng(0, 0),
		mapTypeId	: google.maps.MapTypeId.ROADMAP,
		disableDefaultUI: true,
		scrollwheel	: false
	};

	// create map
	var map = new google.maps.Map( $el[0], args);

	// Change map to greyscale
	var mapstyle = [{ "stylers": [{ "saturation": -100 }] }];
    map.setOptions({ styles: mapstyle });

	// add a markers reference
	var location_markers = [];

	if($el[0].className.indexOf('darwin')) {
		location_markers.push({lat:47.265566, lng:8.3472402}); // Muri
		location_markers.push({lat:47.0061304, lng:9.5042951}); // Bad Ragaz
	} else {
		location_markers.push({lat:47.265566, lng:8.3472402});
		location_markers.push({lat:47.0061304, lng:9.5042951});
	}

	map.markers = [];

	// add markers
    add_markers( location_markers, map, $el[0].className );


	// center map
	center_map( map );

	// return
	return map;

}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

function add_markers( $markers, map, $classes) {

	if($classes.indexOf('darwin')) {
		var marker_path = template_url + '/site/dist/images/map_marker_darwin.svg';
	} else {
		var marker_path = template_url + '/site/dist/images/map_marker_swisswindow.svg';
	}


    var pinImage =  new google.maps.MarkerImage( marker_path );

    var image = {
        url: marker_path,
        size: new google.maps.Size(56, 69),
        scaledSize: new google.maps.Size(56, 69), // IE needed
        anchor: new google.maps.Point(25, 55)
    };
   for (i = 0; i < $markers.length; i++) { 
		// var
		var latlng = new google.maps.LatLng( $markers[i]['lat'], $markers[i]['lng'] );

		// create marker
		var marker = new google.maps.Marker({
			position	: latlng,
			map			: map,
			optimized	: false, // IE needed
			icon		: image
		});

		// add to array
		map.markers.push( marker );
	}

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if( map.markers.length == 1 )
	{
		// set center of map
	    map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	}
	else
	{
		// fit to bounds
		map.fitBounds( bounds );
	}

}